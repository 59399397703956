/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

//// CONTROLLER ////
export class OperationsDestinationDialogController {
    //Bindable
    public buckets: any;

    static get $inject() {
        return [
            '$state',
            '$mdDialog',
            'deliveries',
        ];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public deliveries: any
    ) {
        let vm = this;

        /* BINDABLE: DATA */
        vm.buckets = {};
    }

    /* IMPLEMENTATION : BINDABLE */
    decline() {
        let vm = this;

        vm.$mdDialog.cancel();
    }

    accept() {
        let vm = this;

        vm.$mdDialog.hide(vm.buckets);
    }

    $onInit() {
        let vm = this;
        
        // eslint-disable-next-line
        vm.deliveries.map(function (currPromo:any, index:any, arr:any) {
            if (vm.buckets[currPromo.Destination.id] === undefined) {
                vm.buckets[currPromo.Destination.id] = [];
            }
            vm.buckets[currPromo.Destination.id].push(currPromo);
            vm.buckets[currPromo.Destination.id].Destination = currPromo.Destination;
            vm.buckets[currPromo.Destination.id].OperationsDestination =
                currPromo.OperationsDestination;
            vm.buckets[currPromo.Destination.id].selectedDest = currPromo.Destination;
        });
    }
}
